// --------------------------------------------------------------------------------
// -----------------------------------Colors---------------------------------------
// --------------------------------------------------------------------------------

$black : #000000;
$white : #ffffff;
$ou-red: #E2001A;
//The official OU color:
//$ou-red: #E2001A;


// Palettes
// --------------------------------------------------------------------------------

// Red
$list-red:                             #fde0dc, #f9bdbb, #f69988, #f36c60, #e84e40,
                                       #e51c23, #dd191d, #d01716, #c41411, #b0120a,
                                       #ff7997, #ff5177, #ff2d6f, #e00032;

// Pink
$list-pink:                            #fce4ec, #f8bbd0, #f48fb1, #f06292, #ec407a,
                                       #e91e63, #d81b60, #c2185b, #ad1457, #880e4f,
                                       #ff80ab, #ff4081, #f50057, #c51162;

// Purple
$list-purple:                          #f3e5f5, #e1bee7, #ce93d8, #ba68c8, #ab47bc,
                                       #9c27b0, #8e24aa, #7b1fa2, #6a1b9a, #4a148c,
                                       #ea80fc, #e040fb, #d500f9, #aa00ff;

// Deep Purple
$list-deep-purple:                     #ede7f6, #d1c4e9, #b39ddb, #9575cd, #7e57c2,
                                       #673ab7, #5e35b1, #512da8, #4527a0, #311b92,
                                       #b388ff, #7c4dff, #651fff, #6200ea;

// Indigo
$list-indigo:                          #e8eaf6, #c5cae9, #9fa8da, #7986cb, #5c6bc0,
                                       #3f51b5, #3949ab, #303f9f, #283593, #1a237e,
                                       #8c9eff, #536dfe, #3d5afe, #304ffe;

// Blue
$list-blue:                            #e7e9fd, #d0d9ff, #afbfff, #91a7ff, #738ffe,
                                       #5677fc, #4e6cef, #455ede, #3b50ce, #2a36b1,
                                       #a6baff, #6889ff, #4d73ff, #4d69ff;

// Light Blue
$list-light-blue:                      #e1f5fe, #b3e5fc, #81d4fa, #4fc3f7, #29b6f6,
                                       #03a9f4, #039be5, #0288d1, #0277bd, #01579b,
                                       #80d8ff, #40c4ff, #00b0ff, #0091ea;

// Cyan
$list-cyan:                            #e0f7fa, #b2ebf2, #80deea, #4dd0e1, #26c6da,
                                       #00bcd4, #00acc1, #0097a7, #00838f, #006064,
                                       #84ffff, #18ffff, #00e5ff, #00b8d4;

// Teal
$list-teal:                            #e0f2f1, #b2dfdb, #80cbc4, #4db6ac, #26a69a,
                                       #009688, #00897b, #00796b, #00695c, #004d40,
                                       #a7ffeb, #64ffda, #1de9b6, #00bfa5;

// Green
$list-green:                           #d0f8ce, #a3e9a4, #72d572, #42bd41, #2baf2b,
                                       #259b24, #0a8f08, #0a7e07, #056f00, #0d5302,
                                       #a2f78d, #5af158, #14e715, #12c700;

// Light Green
$list-light-green:                     #f1f8e9, #dcedc8, #c5e1a5, #aed581, #9ccc65,
                                       #8bc34a, #7cb342, #689f38, #558b2f, #33691e,
                                       #ccff90, #b2ff59, #76ff03, #64dd17;

// Lime
$list-lime:                            #f9fbe7, #f0f4c3, #e6ee9c, #dce775, #d4e157,
                                       #cddc39, #c0ca33, #afb42b, #9e9d24, #827717,
                                       #f4ff81, #eeff41, #c6ff00, #aeea00;

// Yellow
$list-yellow:                          #fffde7, #fff9c4, #fff59d, #fff176, #ffee58,
                                       #ffeb3b, #fdd835, #fbc02d, #f9a825, #f57f17,
                                       #ffff8d, #ffff00, #ffea00, #ffd600;

// Amber
$list-amber:                           #fff8e1, #ffecb3, #ffe082, #ffd54f, #ffca28,
                                       #ffc107, #ffb300, #ffa000, #ff8f00, #ff6f00,
                                       #ffe57f, #ffd740, #ffc400, #ffab00;

// Orange
$list-orange:                          #fff3e0, #ffe0b2, #ffcc80, #ffb74d, #ffa726,
                                       #ff9800, #fb8c00, #f57c00, #ef6c00, #e65100,
                                       #ffd180, #ffab40, #ff9100, #ff6d00;

// Deep Orange
$list-deep-orange:                     #fbe9e7, #ffccbc, #ffab91, #ff8a65, #ff7043,
                                       #ff5722, #f4511e, #e64a19, #d84315, #bf360c,
                                       #ff9e80, #ff6e40, #ff3d00, #dd2c00;

// Brown
$list-brown:                           #efebe9, #d7ccc8, #bcaaa4, #a1887f, #8d6e63,
                                       #795548, #6d4c41, #5d4037, #4e342e, #3e2723;

// Grey
$list-grey:                            #fafafa, #f5f5f5, #eeeeee, #e0e0e0, #bdbdbd,
                                       #9e9e9e, #757575, #616161, #424242, #212121,
                                       #000000;

// Blue Grey
$list-blue-grey:                       #eceff1, #cfd8dc, #b0bec5, #90a4ae, #78909c,
                                       #607d8b, #546e7a, #455a64, #37474f, #263238;



// Definitions
// --------------------------------------------------------------------------------

// Red
$red:                              nth($list-red, 6);

$red-50:                           nth($list-red, 1);
$red-100:                          nth($list-red, 2);
$red-200:                          nth($list-red, 3);
$red-300:                          nth($list-red, 4);
$red-400:                          nth($list-red, 5);
$red-500:                          nth($list-red, 6);
$red-600:                          nth($list-red, 7);
$red-700:                          nth($list-red, 8);
$red-800:                          nth($list-red, 9);
$red-900:                          nth($list-red, 10);
$red-A100:                         nth($list-red, 11);
$red-A200:                         nth($list-red, 12);
$red-A400:                         nth($list-red, 13);
$red-A700:                         nth($list-red, 14);


// Pink
$pink:                             nth($list-pink, 6);

$pink-50:                          nth($list-pink, 1);
$pink-100:                         nth($list-pink, 2);
$pink-200:                         nth($list-pink, 3);
$pink-300:                         nth($list-pink, 4);
$pink-400:                         nth($list-pink, 5);
$pink-500:                         nth($list-pink, 6);
$pink-600:                         nth($list-pink, 7);
$pink-700:                         nth($list-pink, 8);
$pink-800:                         nth($list-pink, 9);
$pink-900:                         nth($list-pink, 10);
$pink-A100:                        nth($list-pink, 11);
$pink-A200:                        nth($list-pink, 12);
$pink-A400:                        nth($list-pink, 13);
$pink-A700:                        nth($list-pink, 14);


// Purple
$purple:                           nth($list-purple, 6);

$purple-50:                        nth($list-purple, 1);
$purple-100:                       nth($list-purple, 2);
$purple-200:                       nth($list-purple, 3);
$purple-300:                       nth($list-purple, 4);
$purple-400:                       nth($list-purple, 5);
$purple-500:                       nth($list-purple, 6);
$purple-600:                       nth($list-purple, 7);
$purple-700:                       nth($list-purple, 8);
$purple-800:                       nth($list-purple, 9);
$purple-900:                       nth($list-purple, 10);
$purple-A100:                      nth($list-purple, 11);
$purple-A200:                      nth($list-purple, 12);
$purple-A400:                      nth($list-purple, 13);
$purple-A700:                      nth($list-purple, 14);


// Deep Purple
$deep-purple:                      nth($list-deep-purple, 6);

$deep-purple-50:                   nth($list-deep-purple, 1);
$deep-purple-100:                  nth($list-deep-purple, 2);
$deep-purple-200:                  nth($list-deep-purple, 3);
$deep-purple-300:                  nth($list-deep-purple, 4);
$deep-purple-400:                  nth($list-deep-purple, 5);
$deep-purple-500:                  nth($list-deep-purple, 6);
$deep-purple-600:                  nth($list-deep-purple, 7);
$deep-purple-700:                  nth($list-deep-purple, 8);
$deep-purple-800:                  nth($list-deep-purple, 9);
$deep-purple-900:                  nth($list-deep-purple, 10);
$deep-purple-A100:                 nth($list-deep-purple, 11);
$deep-purple-A200:                 nth($list-deep-purple, 12);
$deep-purple-A400:                 nth($list-deep-purple, 13);
$deep-purple-A700:                 nth($list-deep-purple, 14);


// Indigo
$indigo:                           nth($list-indigo, 6);

$indigo-50:                        nth($list-indigo, 1);
$indigo-100:                       nth($list-indigo, 2);
$indigo-200:                       nth($list-indigo, 3);
$indigo-300:                       nth($list-indigo, 4);
$indigo-400:                       nth($list-indigo, 5);
$indigo-500:                       nth($list-indigo, 6);
$indigo-600:                       nth($list-indigo, 7);
$indigo-700:                       nth($list-indigo, 8);
$indigo-800:                       nth($list-indigo, 9);
$indigo-900:                       nth($list-indigo, 10);
$indigo-A100:                      nth($list-indigo, 11);
$indigo-A200:                      nth($list-indigo, 12);
$indigo-A400:                      nth($list-indigo, 13);
$indigo-A700:                      nth($list-indigo, 14);


// Blue
$blue:                             nth($list-blue, 6);

$blue-50:                          nth($list-blue, 1);
$blue-100:                         nth($list-blue, 2);
$blue-200:                         nth($list-blue, 3);
$blue-300:                         nth($list-blue, 4);
$blue-400:                         nth($list-blue, 5);
$blue-500:                         nth($list-blue, 6);
$blue-600:                         nth($list-blue, 7);
$blue-700:                         nth($list-blue, 8);
$blue-800:                         nth($list-blue, 9);
$blue-900:                         nth($list-blue, 10);
$blue-A100:                        nth($list-blue, 11);
$blue-A200:                        nth($list-blue, 12);
$blue-A400:                        nth($list-blue, 13);
$blue-A700:                        nth($list-blue, 14);


// Light Blue
$light-blue:                       nth($list-light-blue, 6);

$light-blue-50:                    nth($list-light-blue, 1);
$light-blue-100:                   nth($list-light-blue, 2);
$light-blue-200:                   nth($list-light-blue, 3);
$light-blue-300:                   nth($list-light-blue, 4);
$light-blue-400:                   nth($list-light-blue, 5);
$light-blue-500:                   nth($list-light-blue, 6);
$light-blue-600:                   nth($list-light-blue, 7);
$light-blue-700:                   nth($list-light-blue, 8);
$light-blue-800:                   nth($list-light-blue, 9);
$light-blue-900:                   nth($list-light-blue, 10);
$light-blue-A100:                  nth($list-light-blue, 11);
$light-blue-A200:                  nth($list-light-blue, 12);
$light-blue-A400:                  nth($list-light-blue, 13);
$light-blue-A700:                  nth($list-light-blue, 14);


// Cyan
$cyan:                             nth($list-cyan, 6);

$cyan-50:                          nth($list-cyan, 1);
$cyan-100:                         nth($list-cyan, 2);
$cyan-200:                         nth($list-cyan, 3);
$cyan-300:                         nth($list-cyan, 4);
$cyan-400:                         nth($list-cyan, 5);
$cyan-500:                         nth($list-cyan, 6);
$cyan-600:                         nth($list-cyan, 7);
$cyan-700:                         nth($list-cyan, 8);
$cyan-800:                         nth($list-cyan, 9);
$cyan-900:                         nth($list-cyan, 10);
$cyan-A100:                        nth($list-cyan, 11);
$cyan-A200:                        nth($list-cyan, 12);
$cyan-A400:                        nth($list-cyan, 13);
$cyan-A700:                        nth($list-cyan, 14);


// Teal
$teal:                             nth($list-teal, 6);

$teal-50:                          nth($list-teal, 1);
$teal-100:                         nth($list-teal, 2);
$teal-200:                         nth($list-teal, 3);
$teal-300:                         nth($list-teal, 4);
$teal-400:                         nth($list-teal, 5);
$teal-500:                         nth($list-teal, 6);
$teal-600:                         nth($list-teal, 7);
$teal-700:                         nth($list-teal, 8);
$teal-800:                         nth($list-teal, 9);
$teal-900:                         nth($list-teal, 10);
$teal-A100:                        nth($list-teal, 11);
$teal-A200:                        nth($list-teal, 12);
$teal-A400:                        nth($list-teal, 13);
$teal-A700:                        nth($list-teal, 14);


// Green
$green:                            nth($list-green, 6);

$green-50:                         nth($list-green, 1);
$green-100:                        nth($list-green, 2);
$green-200:                        nth($list-green, 3);
$green-300:                        nth($list-green, 4);
$green-400:                        nth($list-green, 5);
$green-500:                        nth($list-green, 6);
$green-600:                        nth($list-green, 7);
$green-700:                        nth($list-green, 8);
$green-800:                        nth($list-green, 9);
$green-900:                        nth($list-green, 10);
$green-A100:                       nth($list-green, 11);
$green-A200:                       nth($list-green, 12);
$green-A400:                       nth($list-green, 13);
$green-A700:                       nth($list-green, 14);


// Light Green
$light-green:                      nth($list-light-green, 6);

$light-green-50:                   nth($list-light-green, 1);
$light-green-100:                  nth($list-light-green, 2);
$light-green-200:                  nth($list-light-green, 3);
$light-green-300:                  nth($list-light-green, 4);
$light-green-400:                  nth($list-light-green, 5);
$light-green-500:                  nth($list-light-green, 6);
$light-green-600:                  nth($list-light-green, 7);
$light-green-700:                  nth($list-light-green, 8);
$light-green-800:                  nth($list-light-green, 9);
$light-green-900:                  nth($list-light-green, 10);
$light-green-A100:                 nth($list-light-green, 11);
$light-green-A200:                 nth($list-light-green, 12);
$light-green-A400:                 nth($list-light-green, 13);
$light-green-A700:                 nth($list-light-green, 14);


// Lime
$lime:                             nth($list-lime, 6);

$lime-50:                          nth($list-lime, 1);
$lime-100:                         nth($list-lime, 2);
$lime-200:                         nth($list-lime, 3);
$lime-300:                         nth($list-lime, 4);
$lime-400:                         nth($list-lime, 5);
$lime-500:                         nth($list-lime, 6);
$lime-600:                         nth($list-lime, 7);
$lime-700:                         nth($list-lime, 8);
$lime-800:                         nth($list-lime, 9);
$lime-900:                         nth($list-lime, 10);
$lime-A100:                        nth($list-lime, 11);
$lime-A200:                        nth($list-lime, 12);
$lime-A400:                        nth($list-lime, 13);
$lime-A700:                        nth($list-lime, 14);


//Yellow
$yellow:                           nth($list-yellow, 6);

$yellow-50:                        nth($list-yellow, 1);
$yellow-100:                       nth($list-yellow, 2);
$yellow-200:                       nth($list-yellow, 3);
$yellow-300:                       nth($list-yellow, 4);
$yellow-400:                       nth($list-yellow, 5);
$yellow-500:                       nth($list-yellow, 6);
$yellow-600:                       nth($list-yellow, 7);
$yellow-700:                       nth($list-yellow, 8);
$yellow-800:                       nth($list-yellow, 9);
$yellow-900:                       nth($list-yellow, 10);
$yellow-A100:                      nth($list-yellow, 11);
$yellow-A200:                      nth($list-yellow, 12);
$yellow-A400:                      nth($list-yellow, 13);
$yellow-A700:                      nth($list-yellow, 14);


// Amber
$amber:                            nth($list-amber, 6);

$amber-50:                         nth($list-amber, 1);
$amber-100:                        nth($list-amber, 2);
$amber-200:                        nth($list-amber, 3);
$amber-300:                        nth($list-amber, 4);
$amber-400:                        nth($list-amber, 5);
$amber-500:                        nth($list-amber, 6);
$amber-600:                        nth($list-amber, 7);
$amber-700:                        nth($list-amber, 8);
$amber-800:                        nth($list-amber, 9);
$amber-900:                        nth($list-amber, 10);
$amber-A100:                       nth($list-amber, 11);
$amber-A200:                       nth($list-amber, 12);
$amber-A400:                       nth($list-amber, 13);
$amber-A700:                       nth($list-amber, 14);


// Orange
$orange:                           nth($list-orange, 6);

$orange-50:                        nth($list-orange, 1);
$orange-100:                       nth($list-orange, 2);
$orange-200:                       nth($list-orange, 3);
$orange-300:                       nth($list-orange, 4);
$orange-400:                       nth($list-orange, 5);
$orange-500:                       nth($list-orange, 6);
$orange-600:                       nth($list-orange, 7);
$orange-700:                       nth($list-orange, 8);
$orange-800:                       nth($list-orange, 9);
$orange-900:                       nth($list-orange, 10);
$orange-A100:                      nth($list-orange, 11);
$orange-A200:                      nth($list-orange, 12);
$orange-A400:                      nth($list-orange, 13);
$orange-A700:                      nth($list-orange, 14);


// Deep Orange
$deep-orange:                      nth($list-deep-orange, 6);

$deep-orange-50:                   nth($list-deep-orange, 1);
$deep-orange-100:                  nth($list-deep-orange, 2);
$deep-orange-200:                  nth($list-deep-orange, 3);
$deep-orange-300:                  nth($list-deep-orange, 4);
$deep-orange-400:                  nth($list-deep-orange, 5);
$deep-orange-500:                  nth($list-deep-orange, 6);
$deep-orange-600:                  nth($list-deep-orange, 7);
$deep-orange-700:                  nth($list-deep-orange, 8);
$deep-orange-800:                  nth($list-deep-orange, 9);
$deep-orange-900:                  nth($list-deep-orange, 10);
$deep-orange-A100:                 nth($list-deep-orange, 11);
$deep-orange-A200:                 nth($list-deep-orange, 12);
$deep-orange-A400:                 nth($list-deep-orange, 13);
$deep-orange-A700:                 nth($list-deep-orange, 14);


// Brown
$brown:                            nth($list-brown, 6);

$brown-50:                         nth($list-brown, 1);
$brown-100:                        nth($list-brown, 2);
$brown-200:                        nth($list-brown, 3);
$brown-300:                        nth($list-brown, 4);
$brown-400:                        nth($list-brown, 5);
$brown-500:                        nth($list-brown, 6);
$brown-600:                        nth($list-brown, 7);
$brown-700:                        nth($list-brown, 8);
$brown-800:                        nth($list-brown, 9);
$brown-900:                        nth($list-brown, 10);


// Grey
$grey:                             nth($list-grey, 6);

$grey-50:                          nth($list-grey, 1);
$grey-100:                         nth($list-grey, 2);
$grey-200:                         nth($list-grey, 3);
$grey-300:                         nth($list-grey, 4);
$grey-400:                         nth($list-grey, 5);
$grey-500:                         nth($list-grey, 6);
$grey-600:                         nth($list-grey, 7);
$grey-700:                         nth($list-grey, 8);
$grey-800:                         nth($list-grey, 9);
$grey-900:                         nth($list-grey, 10);
$grey-1000:                        nth($list-grey, 11);


// Blue Grey
$blue-grey:                        nth($list-blue-grey, 6);

$blue-grey-50:                     nth($list-blue-grey, 1);
$blue-grey-100:                    nth($list-blue-grey, 2);
$blue-grey-200:                    nth($list-blue-grey, 3);
$blue-grey-300:                    nth($list-blue-grey, 4);
$blue-grey-400:                    nth($list-blue-grey, 5);
$blue-grey-500:                    nth($list-blue-grey, 6);
$blue-grey-600:                    nth($list-blue-grey, 7);
$blue-grey-700:                    nth($list-blue-grey, 8);
$blue-grey-800:                    nth($list-blue-grey, 9);
$blue-grey-900:                    nth($list-blue-grey, 10);
