@import '../../styling/global.scss';
.notFound{
    position:absolute;
    left: calc(50% - 250px);
    width: 500px;
    overflow: hidden;
    text-align: center;
    h1{
        font-size:24px;
    }
}