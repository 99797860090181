@import '../../styling/global.scss';
.refreshBar {
    width: 100%;
    display:block;
    height:40px;
    margin-top:20px;
    margin-bottom:20px;
}
.datePickerEnd {
    display: inline-block;
    margin-left: 20px;
}
.datePickerStart {
    display: inline-block;
}

.loadingSpinner{
    top:-20px;
    position: absolute;
    left: calc(50% - 100px);
    width: 200px;
}