@import '../../styling/global.scss';
.wrapper {
    position: absolute;
    left: calc(50% - 100px);
    width: 200px;
    overflow: hidden;
    text-align: center;
    .loadingSpinner {
        .spinnerDot1,
        .spinnerDot2,
        .spinnerDot3 {
            display: inline-block;
            color: $ou-red;
            font-family: 'Font Awesome';
            width: 50px;
            height: 50px;
            font-weight: 900;
            animation: change-size 900ms infinite;
            font-size: 20px;
            vertical-align: text-top;
            &:before {
                content: "\f111";
            }
        }
        .spinnerDot2 {
            animation-delay: -450ms;
        }
    }
}

@keyframes change-size {
    0% {
        font-size: 10px;
        margin-top: 20px;
    }
    50% {
        font-size: 30px;
        margin-top: 0;
    }
    100% {
        font-size: 10px;
        margin-top: 20px;
    }
}

@-moz-keyframes change-size {
    0% {
        font-size: 10px;
        margin-top: 20px;
    }
    50% {
        font-size: 30px;
        margin-top: 0;
    }
    100% {
        font-size: 10px;
        margin-top: 20px;
    }
}

@-webkit-keyframes change-size {
    0% {
        font-size: 10px;
        margin-top: 20px;
    }
    50% {
        font-size: 30px;
        margin-top: 0;
    }
    100% {
        font-size: 10px;
        margin-top: 20px;
    }
}

@-ms-keyframes change-size {
    0% {
        font-size: 10px;
        margin-top: 20px;
    }
    50% {
        font-size: 30px;
        margin-top: 0;
    }
    100% {
        font-size: 10px;
        margin-top: 20px;
    }
}

@-o-keyframes change-size {
    0% {
        font-size: 10px;
        margin-top: 20px;
    }
    50% {
        font-size: 30px;
        margin-top: 0;
    }
    100% {
        font-size: 10px;
        margin-top: 20px;
    }
}