@font-face {
    font-family: 'Open Sans';
            src: url('./fonts/Open Sans/OpenSansRegular.eot');
            src: url('./fonts/Open Sans/OpenSansRegular.eot') format('embedded-opentype'),
                url('./fonts/Open Sans/OpenSansRegular.woff2') format('woff2'),
                url('./fonts/Open Sans/OpenSansRegular.woff') format('woff'),
                url('./fonts/Open Sans/OpenSansRegular.ttf') format('truetype'),
                url('./fonts/Open Sans/OpenSansRegular.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
            src: url('./fonts/Open Sans/OpenSansBold.eot');
            src: url('./fonts/Open Sans/OpenSansBold.eot') format('embedded-opentype'),
                url('./fonts/Open Sans/OpenSansBold.woff2') format('woff2'),
                url('./fonts/Open Sans/OpenSansBold.woff') format('woff'),
                url('./fonts/Open Sans/OpenSansBold.ttf') format('truetype'),
                url('./fonts/Open Sans/OpenSansBold.svg#OpenSansBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
        src: url('./fonts/Open Sans/OpenSansItalic.eot');
        src: url('./fonts/Open Sans/OpenSansItalic.eot') format('embedded-opentype'),
            url('./fonts/Open Sans/OpenSansItalic.woff2') format('woff2'),
            url('./fonts/Open Sans/OpenSansItalic.woff') format('woff'),
            url('./fonts/Open Sans/OpenSansItalic.ttf') format('truetype'),
            url('./fonts/Open Sans/OpenSansItalic.svg#OpenSansItalic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Font Awesome';
            src: url('./fonts/Font Awesome/fa-regular-400.eot');
            src: url('./fonts/Font Awesome/fa-regular-400.eot') format('embedded-opentype'),
                url('./fonts/Font Awesome/fa-regular-400.woff2') format('woff2'),
                url('./fonts/Font Awesome/fa-regular-400.woff') format('woff'),
                url('./fonts/Font Awesome/fa-regular-400.ttf') format('truetype'),
                url('./fonts/Font Awesome/fa-regular-400.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome';
            src: url('./fonts/Font Awesome/fa-solid-900.eot');
            src: url('./fonts/Font Awesome/fa-solid-900.eot') format('embedded-opentype'),
                url('./fonts/Font Awesome/fa-solid-900.woff2') format('woff2'),
                url('./fonts/Font Awesome/fa-solid-900.woff') format('woff'),
                url('./fonts/Font Awesome/fa-solid-900.ttf') format('truetype'),
                url('./fonts/Font Awesome/fa-solid-900.svg') format('svg');
    font-weight: 900;
    font-style: normal;
}