@import '../../styling/global.scss';
.modalOverlay {
    background-color: $grey-50;
    opacity: 0.6;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.modal {
    position: fixed !important;
    min-width: 500px;
    top: 200px;
    left: calc(50% - 250px);
    opacity: 1;
    z-index: 11;
}

:global {
    //Is a global class to disable scrolling for the whole body when the modal is open
    body.modal-open {
        overflow: hidden;
    }
}