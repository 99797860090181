@import '../../styling/global.scss';
.buttonRegular {
    display: inline-block;
    text-align: center;
    border: 1px solid $ou-red;
    border-radius: .25rem;
    background-color: $white;
    color: $ou-red;
    font-size: 14px;
    line-height: 14px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px;
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
        background-color: $ou-red;
        border: 1px solid $ou-red;
        color: $white;
    }
    &:active {
        padding:10px;
        margin:2px;
        font-size:13px;
    }
}

.buttonLink{
    border:0;
    border-bottom:1px solid $ou-red;
    color:$ou-red;
    background-color:$white;
    font-size: 14px;
    line-height: 14px;   
    padding: 6px 0;
    margin: 6px 0;
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
        border-bottom: 2px solid $ou-red;
        margin-bottom:5px;
    }
    &:active {
        font-weight: bold;
    }

}

.buttonWithIcon {
    display: inline-block;
    text-align: center;
    border: 1px solid $ou-red;
    border-radius: .25rem;
    background-color: $white;
    color: $ou-red;
    font-size: 14px;
    line-height: 14px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px;
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
        background-color: $ou-red;
        border: 1px solid $ou-red;
        color: $white;
    }
    &:active {
        padding:10px;
        margin:2px;
        font-size:13px;
    }
    &:after {
        background-color: $ou-red;
        font-size: 14px;
        line-height: 14px;
        padding: 12px;
        font-family: 'Font Awesome';
        color: $white;
        margin-left: 6px;
        margin-right: -12px;
        border: $white;
    }

    &.refresh{
        &:after {
        content: '\f021';
        }
    }
    
    &.calendar{
        &:after {
        content: '\f073';
        }
    }

    &.resend{
        &:after {
        content: '\f362';
        }
    }

    &.delete{
        &:after {
        content: '\f1f8';
        }
    }


}


/*
.pagingButton{
    composes: primaryButton;
}
*/