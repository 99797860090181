@import '../../styling/global.scss';
nav.main,
div.secondary {
    float: left;
    ul {
        > li {
            list-style-type: none;
            > a {
                line-height: 60px;
                padding: 10px 15px 10px 0;
                text-decoration: none;
                font-family: 'Open Sans';
                font-size: 16px;
                font-size: 1rem;
                color: $grey-600;
                position: relative;
                display: block;
                &:hover,
                &.active {
                    color: $black;
                    text-decoration: underline;
                }
                &:active {
                    font-weight: bold;
                }
            }
        }
    }
}

div.secondary {
    float: right;
    ul>li>a {
        padding: 10px 0 10px 15px;
    }
}