@import '../../styling/global.scss';
.header {
    height: 80px;
    width: 100%;
    z-index: 999;
    background: $white;
    border-bottom: 1px solid $grey-400;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);

    .logo{
        background-image:url('../../styling/img/OUADP-icon-md.png');
        background-size:contain;
        background-repeat:no-repeat;
        margin:6px 10px 6px 0;
        width:35px;
        height:66px;
        float:left;
    }

}