@import '../../styling/global.scss';

.logoRow {
    width: 30%;
    margin-top:-20px;
    float:left;
    img {
        width: 45%;
        height: auto;
    }
}

@media screen and (max-width: 450px){
    .wrapper{
        width: 100%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width: 451px) and (max-width: 570px){
    .wrapper{
        width: 75%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:571px) and (max-width:1300px){
    .wrapper{
        width: 100%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width: 1301px) and (max-width:1500px){
    .wrapper{
        width: 85%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:1501px) and (max-width:1600px) {
    .wrapper{
        width: 80%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:1601px) and (max-width:2100px){
    .wrapper {
        width: 60%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width: 2101px) and (max-width:2600px){
    .wrapper {
        width: 50%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width: 2601px) and (max-width:2900px){
    .wrapper {
        width: 45%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:2901px) and (max-width:3200px){
    .wrapper {
        width: 40%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:3201px) and (max-width:3650px){
    .wrapper {
        width: 35%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    } 
}

@media screen and (min-width: 3651px) and (max-width:4250px) {
    .wrapper {
        width: 30%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    } 
}

@media screen and (min-width:4251px) and (max-width:5000px){
    .wrapper {
        width: 25%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    } 
}

@media screen and (min-width:5001px) and (max-width:6300px) {
    .wrapper {
        width: 20%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    } 
}

@media screen and (min-width:6301px) and (max-width:8400px){
    .wrapper {
        width: 15%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    }
}

@media screen and (min-width:8401px){
    .wrapper {
        width: 10%;
        margin: 150px auto 0 auto;
        padding: 3em 2em 2em 2em;
        background: #fafafa;
        border: 1px solid #ebebeb;
        box-shadow: rgba(0, 0, 0, 0.14902) 0px 1px 1px 0px, rgba(0, 0, 0, 0.09804) 0px 1px 2px 0px;
        position: relative;
        font-family: "Open Sans";
    } 
}

/*
@media screen and (min-width: 2000px){
    .logoRow{
        width: 30%;
        margin-top:2px;
        float: left;
        img{
            width: 68%;
            height: auto;
        }
    }
}

@media screen and (min-width: 2200px){
    .logoRow{
        width: 30%;
        margin-top:2px;
        float: left;
        img{
            width: 62%;
            height: auto;
        }
    }
}

@media screen and (min-width: 2400px){
    .logoRow{
        width: 30%;
        margin-top:0px;
        float: left;
        img{
            width: 55%;
            height: auto;
        }
    }
}

@media screen and (min-width: 2740px){
    .logoRow{
        width: 30%;
        margin-top:0px;
        float: left;
        img{
            width: 48%;
            height: auto;
        }
    }
}

@media screen and (min-width: 3000px){
    .logoRow{
        width: 30%;
        margin-top:0px;
        float: left;
        img{
            width: 40%;
            height: auto;
        }
    } 
}*/

.formRow {
    width: 70%;
    display:inline-block;
}

.formGroup {
    position: relative;
    margin-bottom: 20px;
}

.formGroupCheckbox {
    position: relative;
    margin-bottom: 20px;
    padding: 0 1.5em;
}

.actionRow {
    text-align: right;
}

.validationRow{
    width: 100%;
    padding: 1em 2em 1em 2em;
    background-color:$deep-orange-100;
    font-family: "Open Sans";
    border: 1px solid $red-100;
    position: relative;
    color:$red-900;
    text-align:center;
}

.loginBtn{
    width: 40%;
    display: inline-block;
    text-align: center;
    border: 1px solid $ou-red;
    border-radius: .25rem;
    background-color: $white;
    color: $ou-red;
    font-size: 16px;
    line-height: 14px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    padding: 12px;
    &:focus,
    &:hover {
        outline: 0;
        box-shadow: none;
        background-color: $ou-red;
        border: 1px solid $ou-red;
        color: $white;
    }
    &:active {
        padding:10px;
        margin:2px;
        font-size:13px;
    }
}