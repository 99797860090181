@import '../../styling/global.scss';
.pagingControls {
    float: right;
}

:global {
    //overwrite the bootstrap pagination colors
    a.page-link {
        color: $ou-red;
        &:hover {
            border-color: $ou-red;
            background-color: $ou-red;
            color: $white;
        }
        &:focus {
            box-shadow: none
        }
    }
    .page-item.active .page-link {
        background-color: $ou-red !important;
        border-color: $ou-red !important;
    }
}