@import '../../styling/global.scss';
//Because the react-data-grid package uses their own stylesheet which we cannot overwrite we are bound to using :global here
:global {
    .react-grid-HeaderCell {
        .form-control {
            height: calc(1.5em + .75rem + -4px);
        }
    }
    .react-grid-Grid{
        overflow: visible !important;
        .react-grid-HeaderRow{
            min-height: 250px !important;
        }
    }
    .react-grid-checkbox:checked+.react-grid-checkbox-label::before {
        background: $ou-red;
    }
    .oddRow {
        .react-grid-Cell {
            background-color: $white !important;
        }
    }
    .evenRow {
        .react-grid-Cell {
            background-color: $grey-50 !important;
        }
    }
    .parentRow {
        .react-grid-Cell {
            background-color: $grey-300 !important;
        }
    }
    .childRow {
        .react-grid-Cell {
            background-color: $grey-200 !important;
        }
    }
    .message-has-error {
        color: $ou-red;
    }

    .selectedRow {
        .react-grid-Cell {
            background-color: $light-blue-50 !important;
        }
    }

    .clickedRow {
        .react-grid-Cell {
            background-color: $orange-50 !important;
        }
    }

}

.noBerichten {
    font-size: 20px;
    line-height: 50px;
    height: 50px;
    text-align: center;
}