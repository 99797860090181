@import '../../styling/global.scss';

.faIcon{
    padding-left: 4px;

    &:after {
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        font-family: 'Font Awesome';
        color: $ou-red;
        content:'\f3bf';
        
    }

    &.envelope{
        &:after {
            content:'\f0e0';
        }
    }
    
    &.level-up-alt{
        &:after {
            transform: rotate(90deg);
            content:'\f3bf';
            margin-left:4px;
        }
    }

}