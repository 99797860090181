@import '../../styling/global.scss';
.pagingBar {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    .controlRow {
        margin: 10px 0;
        button:nth-child(n+2) {
            margin-left: 10px;
        }
    }
}